import React, { Fragment, useEffect } from "react";
import { Grid, IconButton, Typography } from "@mui/material";
import { useDispatch, useSelector } from 'react-redux';
import classes from './InformeDisponible.module.css';
import { storageUsuarioId } from "../../../../assets/shared/sessionData";
import Spinner from "../../Spinner/Spinner";
import Dialog from "../../Dialog/Dialog";
import { closeInformeDisponibleModal, loadInformesDisponibles } from "../../../../store/slices/tipoInformeSlice";
import { loadTiposUI } from "../../../../store/slices/tipoAlertaSlice";
import { Close } from "@mui/icons-material";
import InformeDisponibleCategoriaCard from "./InformeDisponibleCategoriaCard";
import InformeDisponibleDetalle from "./InformeDisponibleDetalle";
import InformeDisponibleAyuda from "./InformeDisponibleAyuda";
import InformeConfiguradoDetalle from "../InformesConfigurados/InformeConfiguradoDetalle";

const InformeDisponibleList = (props) => {
  //#region Dispatches
  const dispatch = useDispatch();

  const loading = useSelector(state => state.tipoInforme.informesDisponibles.loading);
  const estaCargado = useSelector(state => state.tipoInforme.informesDisponibles.estaCargado);
  const informes = useSelector(state => state.tipoInforme.informesDisponibles.informes);
  const dialogState = useSelector(state => state.tipoInforme.informeDisponibleModal);

  const loadingTiposUI = useSelector(state => state.tipoAlertas.tiposUI.loading);
  const estaCargadoTiposUI = useSelector(state => state.tipoAlertas.tiposUI.estaCargado);

  const empresasDelUsuario = useSelector(state => state.multiempresa.empresasDelUsuario.empresas);
  const usuariosDelCliente = useSelector(state => state.clientes.usuarios.usuarios);
  //#endregion

  const showModalContent = () => {
    if (dialogState.type === 'ayuda')
      return showAyudaInforme(dialogState.informe);
    if (dialogState.type === 'configurar')
      return showConfigurarInforme(dialogState.informe);
    if (dialogState.type === 'editar')
      return showEditarInforme(dialogState.informe);
  }

  const showAyudaInforme = (informe) => {
    return (
      <InformeDisponibleAyuda informe={informe} />
    )
  }

  const showConfigurarInforme = (informe) => {
    const newInforme = {
      id: 0,
      idTipoInforme: informe.id,
      tipoInforme: informe,
      idEmpresa: '',
      responsable: '',
      tituloInforme: informe.tituloGauss,
      descripCionInforme: '',
      horarioEnvio: null,
      archivoAdjunto: '',
      notificarPorMail: true,
      habilitada: false,
      versionInforme: 1,
      borrado: false,
      leida: false,
      configuracionesDefault: [],
      ultimaEjecucion: null,
      destinatariosEmail: [],
      destinatariosApp: []
    };
    return (
      <InformeConfiguradoDetalle
        informe={newInforme}
        empresas={empresasDelUsuario}
        usuarios={usuariosDelCliente}
        canEdit={true}
        create={true}
      />
    )
  }

  const showEditarInforme = (informe) => {
    return (
      <InformeDisponibleDetalle
        informe={informe}
        canEdit={true}
        onCloseModal={() => dispatch(closeInformeDisponibleModal())}
      />
    )
  }

  const datosPorCategoria = (informes) => {
    if (informes && informes.length > 0) {
      var items = [];
      const informesPorCateg = Map.groupBy(informes, informe => { return informe.categoria; });
      informesPorCateg.forEach(element => {
        items.push({
          categoria: element[0] && element[0].categoria,
          informes: element
        })
      });
      return items;
    }

    return [];
  }

  useEffect(() => {
    if (!estaCargado && !loading)
      dispatch(loadInformesDisponibles(storageUsuarioId()));
  }, [estaCargado, loading, dispatch]);

  useEffect(() => {
    if (!estaCargadoTiposUI && !loadingTiposUI)
      dispatch(loadTiposUI());
  }, [estaCargadoTiposUI, loadingTiposUI, dispatch]);

  return (
    <Fragment>
      <Grid container spacing={1} className={classes.PanelContainer} justifyContent='flex-start' alignContent='flex-start'>
        <Grid item xs={12} sx={{ marginBottom: '7px' }}>
          <Typography variant="h5">
            Informes Disponibles
          </Typography>
        </Grid>
        <Grid item xs={12} container spacing={1} direction='row' justifyContent='space-evenly'>
          {loading ? (
            <div style={{ textAlign: "center", marginTop: "20px" }}>
              <Spinner />
            </div>
          ) : (
            <Fragment>
              {informes && datosPorCategoria(informes).map((item, index) => {
                return (
                  <InformeDisponibleCategoriaCard
                    key={index}
                    index={index}
                    categoria={item.categoria}
                    informes={item.informes}
                  />
                )
              })}
            </Fragment>
          )}
        </Grid>
      </Grid>
      <Dialog
        title=
        {
          <div classes={classes.ModalTitleContent} style={{ width: "100%" }}>
            <div className={classes.ModalTitleTexto}>
              <Typography variant="h5">{dialogState.title}</Typography>
              <IconButton aria-label="close" onClick={() => dispatch(closeInformeDisponibleModal())} >
                <Close />
              </IconButton>
            </div>
            {dialogState && dialogState.subTitle && dialogState.subTitle !== ''
              && (<div className={classes.ModalSubtitleTexto}>
                <Typography variant="caption">
                  {dialogState.subTitle}
                </Typography>
              </div>
              )}
          </div>
        }
        fullWidth={false}
        fullScreen={false}
        styleContent={{
          padding: dialogState.type === 'history'
            ? '10px 0px 0px'
            : dialogState.type !== 'execute' ? '20px 40px 40px 40px' : undefined
        }}
        maxWidth={dialogState.maxWidth ? dialogState.maxWidth : 'md'}
        show={dialogState.show}
        body={
          <div
            className={classes.ModalBodyDialog}
            style={props.loadingBodyDialog ? { display: "flex", justifyContent: "center" } : undefined}
          >
            {showModalContent()}
          </div>
        }
        onClose={() => dispatch(closeInformeDisponibleModal())}
      />
    </Fragment>
  )
}

export default InformeDisponibleList;